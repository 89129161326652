




























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import HorizontalScroll from "@/components/HorizontalScroll.vue";

@Component({
  components: {
    HorizontalScroll,
  },
})
export default class HomeScroller extends Vue {
  @Prop(String) readonly title: string;
  @Prop(String) readonly to?: string;
}
