
































































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

import { Game } from "@/store/modules/games";

import Header from "@/components/Header.vue";
import NewHeader from "@/components/NewHeader.vue";
import AddToHomeTuto from "@/components/AddToHomeTuto.vue";
import HorizontalScroll from "@/components/HorizontalScroll.vue";
import HomeScroller from "@/components/HomeScroller.vue";
import Banner from "@/components/Banner.vue";

@Component({
  components: {
    Header,
    NewHeader,
    AddToHomeTuto,
    HorizontalScroll,
    CoolLightBox,
    HomeScroller,
    Banner,
  },
})
export default class GameView extends Vue {
  tutoDialog = false;
  currentSShot = null;

  get screenshots(): string[] {
    return this.game?.screenshots?.map((screenshot) => screenshot.url) || [];
  }

  get favorite(): boolean {
    if (!this.game) return false;
    return this.$store.direct.state.games.favoriteGames.includes(this.game._id);
  }

  get isPortrait(): boolean {
    return this.game?.orientation === "PORTRAIT";
  }

  async toggleFavorite(): Promise<void> {
    if (!this.game) return;
    if (this.$store.direct.state.authentication.user?.temporaryAccount) {
      this.$store.direct.commit.SHOW_LOGIN_POPUP({
        onLogin: async () => {
          if (!this.game) return;
          await this.$store.direct.dispatch.games.addToFavorite(this.game);
        },
      });

      return;
    }
    if (this.favorite) await this.$store.direct.dispatch.games.removeFromFavorite(this.game);
    else await this.$store.direct.dispatch.games.addToFavorite(this.game);
  }

  get pegiRating(): number {
    return this.game?.contentRating?.pegi?.rating || 3;
  }

  get game(): Game | undefined {
    return this.$store.direct.getters.games.getGame(this.$route.params.game);
  }

  get similarGames(): Game[] {
    return (
      this.game?.similarGames
        ?.map((gameID) => this.$store.direct.state.games.games.find((game) => game._id === gameID) as Game)
        .filter((g) => g) || []
    );
  }

  get publisher(): string {
    return this.$store.direct.state.games.publishers.find((p) => p._id === this.game?.publisher)?.name || "";
  }
}
